import { css } from "@emotion/react"
import { md } from "@styles/breakpoints"
import { colors } from "@styles/variables"

const systemStatusStyles = {
  link: css`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  message: css`
    font-size: 14px;
    color: ${colors.grey};
    text-transform: uppercase;

    ${md} {
      text-align: right;
    }
  `,
  icon: css`
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;

    &.none {
      background-color: green;
    }

    &.minor {
      background-color: yellow;
    }

    &.major {
      background-color: orange;
    }

    &.critical {
      background-color: red;
    }
  `,
}

export { systemStatusStyles }
