import { css } from "@emotion/react"
import { colors } from "@styles/variables"
import { sm } from "@styles/breakpoints"

const cookieToggler = css`
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 2;
  padding: 16px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  ${sm} {
    right: 40px;
    bottom: 40px;
  }
`

const cookieManager = css`
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 2;
  width: calc(100% - 30px);
  padding: 24px;
  color: white;
  background: ${colors.blackish};
  box-shadow: 0 0 2px 0 rgba(255, 255, 255, 0.25);

  ${sm} {
    right: 40px;
    bottom: 40px;
    width: auto;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .description {
    max-width: 350px;
    margin: 24px 0;
    color: ${colors.grey};
  }

  .form-header {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.96);
  }

  button {
    width: 100%;
    margin-top: 24px;
    border: none;
  }

  .checkbox-wrapper {
    margin-bottom: 12px;
  }
`

export { cookieToggler, cookieManager }
