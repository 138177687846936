import React, { useState, useEffect, useContext } from "react"
import { CookiesContext } from "../../context/cookies-context"
import { cookieToggler, cookieManager } from "./cookie-manager-styles"
import { Checkbox } from "@components/checkbox/checkbox"
import { Button } from "@components/button/button"

import CookieIcon from "@images/cookies.inline.svg"

const CookieManager: React.FC = () => {
  const [showManager, setManagerVisibility] = useState(false)
  const { permissions, saveContext } = useContext(CookiesContext)

  useEffect(() => {
    if (
      permissions.readFromStorage === true &&
      permissions.accepted === false
    ) {
      setManagerVisibility(true)
    }

    if (!permissions.performance && window.ga) {
      ga("set", "anonymizeIp", true)
    }
  }, [permissions])

  const saveSettings = () => {
    saveContext({
      ...permissions,
      accepted: true,
    })

    setManagerVisibility(false)
  }

  if (showManager) {
    return (
      <div className="cookie-manager-open" css={cookieManager}>
        <span className="title">Cookie Settings</span>
        <p className="description">
          This website uses cookies to ensure you get the best experience on our
          website.
        </p>
        <p className="form-header">Allowed Cookies</p>

        <Checkbox
          label="Strict"
          active={permissions.strict}
          onClick={() => {
            saveContext({
              ...permissions,
              strict: !permissions.strict,
            })
          }}
          disabled={true}
        ></Checkbox>
        <Checkbox
          label="Functional"
          active={permissions.functional}
          onClick={() => {
            saveContext({
              ...permissions,
              functional: !permissions.functional,
            })
          }}
        ></Checkbox>
        <Checkbox
          label="Performance"
          active={permissions.performance}
          onClick={() => {
            saveContext({
              ...permissions,
              performance: !permissions.performance,
            })
          }}
        ></Checkbox>
        <Checkbox
          label="Marketing"
          active={permissions.marketing}
          onClick={() => {
            saveContext({
              ...permissions,
              marketing: !permissions.marketing,
            })
          }}
        ></Checkbox>
        <Button label="Got it" onClick={() => saveSettings()} type="fill" />
      </div>
    )
  } else {
    return (
      <div
        className="cookie-manager-toggle"
        css={cookieToggler}
        onClick={() => setManagerVisibility(true)}
      >
        <CookieIcon />
      </div>
    )
  }
}

export { CookieManager }
