import React, { useState, useEffect, useContext } from "react"
import { CookiesContext } from "../../context/cookies-context"
import { systemStatusStyles } from "./system-status.styles"

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    StatusPage: any
  }
}

const SystemStatus: React.FC = () => {
  const [status, setStatus] = useState<string | null>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [description, setDescription] = useState<string | null>("")
  const { permissions } = useContext(CookiesContext)

  useEffect(() => {
    if (!document.getElementById("StatusPage")) {
      const s = document.createElement("script")
      s.id = "StatusPage"
      s.type = "text/javascript"
      s.async = true
      s.src = "https://cdn.statuspage.io/se-v2.js"
      document.body.appendChild(s)
      s.onload = () => {
        setIsLoaded(true)
      }
    }
  }, [])

  useEffect(() => {
    if (permissions.accepted && permissions.functional && isLoaded) {
      if (window && window.StatusPage && window.StatusPage.page) {
        const sp = new window.StatusPage.page({ page: "hl9qf0clsw4l" })
        sp.summary({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          success: (data: any) => {
            setStatus(data.status.indicator)
            setDescription(data.status.description)
          },
        })
      }
    } else {
      setStatus(null)
      setDescription(null)
    }
  }, [permissions, isLoaded])

  if (status) {
    return (
      <div>
        <a
          css={systemStatusStyles.link}
          href={`https://authsider.statuspage.io`}
          target={`_blank`}
          rel={`noopener`}
        >
          <span css={systemStatusStyles.icon} className={status}></span>
          <span css={systemStatusStyles.message}>{description}</span>
        </a>
      </div>
    )
  } else {
    return null
  }
}

export { SystemStatus }
